@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Thin.ttf") format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Light.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Roboto;
  src: url("./assets/fonts/Roboto/Roboto-Black.ttf") format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-Thin.ttf") format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-Light.ttf") format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-Regular.ttf") format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-Medium.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-SemiBold.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-Bold.ttf") format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-ExtraBold.ttf") format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: Inter;
  src: url("./assets/fonts/Inter/Inter-Black.ttf") format('truetype');
  font-weight: 900;
}