/* TemplateItem */

.container{
  flex-basis: calc(100% / 4);
  padding: 0.3em 0.4em;
  box-sizing: border-box;
  height:3.2em;
  overflow: hidden;
}

.inner{
  height:100%;
  width: 100%;
  background-color:#f9f9f9;
  border-radius: 8px;
  border: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
}

.titleholder{
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 0.5em;
  cursor: pointer;
}

.title {
  width:100%;
  display: block;
  font-weight: 400;
  font-size: 1.1em;
  overflow: hidden;
  white-space: nowrap;
  color: #282828;
  text-align: center;
}

@media (max-width: 600px) {
  .container {
    flex-basis: 100%;
  }
}

@media (min-width: 601px) {
  .container {
    flex-basis: 50%;
  }
}

@media (min-width: 950px) {
  .container {
    flex-basis: calc(100% / 3);
  }
}

@media (min-width: 1300px) {
  .container {
    flex-basis: calc(100% / 4);
  }
}