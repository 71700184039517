.optionsbuttoncontainer {
  width: 20px;
  height: 100%;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
  cursor: pointer;
}
/* 
.optionsbuttoncontainer:hover {
  background-color: var(--brown-grey);
} */

.dot {
  margin: 1px 0;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: var(--greyish);
}

.main {
  position: relative;
}

.dropdowncontainer {
  position: absolute;
  cursor:pointer;
  z-index: 1;
  width: 150px;
  overflow-x: hidden;
  overflow-y: hidden;
  height: fit-content;
  max-height: 120px;
  border-radius: 8px;
  background-color: #ff0000;
  right: 0;
  top: calc(100% + 2px);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(209, 209, 209, 0.35);
  -moz-box-shadow: 0px 1px 4px 0px rgba(209, 209, 209, 0.35);
  box-shadow: 0px 1px 4px 0px rgba(209, 209, 209, 0.35);
}

.hidden {
  display: none;
  height: 0px;
}

.option {
  height: 30px;
  width:100%;
  border-radius: 8px;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0000;
  font-size: 1.1em;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #ff0000;
}