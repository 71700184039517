.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92vh;
}
.centercontainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.containerModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
}
.containerModal .dialogContent {
  padding-bottom: 4em;
}
.new_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 14px;
}
.label {
  display: block;
  font-size: 0.8em;
  margin-bottom: 0.2em;
  margin-top: 0.5em;
}
.textarea {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: #282828 !important;
  border: 1px solid #e6e6e6;
  background: #f9f9f9;
  padding: 14px 12px;
  border-radius: 8px;
  min-height: 6em;
  font-size: 16px;
  min-width: 100%;
  font-family: "Inter", sans-serif;
  max-width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.fillbtn button {
  width: 100%;
}
.switchbuttonsgrid {
  margin-bottom: 1em !important;
}
.switchbuttonsgrid .buttonToggle {
  width: 100%;
  padding: 0.8125em;
  font-size: 1em;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 8px;
}
.switchbuttonsgrid .setactivebutton {
  color: #000;
  border-color: #000;
}
.backicon {
  font-size: 2.3125em;
  cursor: pointer;
}
.titlebox .title {
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  color: #282828;
}
.titlebox {
  display: flex;
  align-items: center;
  margin-bottom: 1.25em;
}
.backicon {
  font-size: 2.3125em;
  cursor: pointer;
}
.cancelbuttonmargin {
  margin-right: 8px;
  width: 100%;
}
.buttonsholder .fillbtn,
.buttonsholder .outlinebtn {
  width: 100%;
}
.buttonsholder .outlinebtn {
  background: transparent;
  color: #1890ff;
  border-color: #1890ff;
}
.submitbuttonsholder {
  display: flex;
  width: 100%;
}
.buttonsholder {
  margin-top: 0.3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.errorholder {
  display: flex;
  justify-content: flex-start;
}
.submitbuttonsholder {
  display: flex;
  width: 100%;
}
.errortext {
  margin-top: 15px;
}
.errortext .container {
  margin-top: 15px;
}
.text {
  color: var(--deep-red);
  font-size: 0.85em;
  line-height: 2em;
  text-align: center;
  display: block;
}

/*  */
.new_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.new_container .submitbuttonsholder {
  margin-top: 20px;
}
.content_area {
  font-size: 16px !important;
  margin-bottom: 10px;
  max-width: 380px;
}

@media only screen and (max-width: 1200px) {
  .outlinebtn button,
  .fillbtn button {
    font-size: 0.9em;
  }
}
@media only screen and (max-width: 999px) {
  .shiftdelete .shiftbtn,
  .shiftdelete .shiftdeletebtn {
    padding: 10px 12px;
  }
}
@media only screen and (max-width: 767px) {
  .textarea {
    font-size: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .titlebox .title {
    font-size: 1.6em;
  }
  .shiftdelete .shiftbtn,
  .shiftdelete .shiftdeletebtn {
    padding: 10px;
    font-size: 14px;
  }
  .shiftdelete h2 {
    font-size: 20px;
  }
  .backicon {
    font-size: 1.913em;
  }
  .switchbuttonsgrid .buttonToggle {
    font-size: 0.9em;
    height: auto;
  }
  .buttonsholder .fillbtn,
  .buttonsholder .outlinebtn {
    padding: 0.513em;
  }
}

@media only screen and (max-width: 480px) {
  .buttonsholder .fillbtn,
  .buttonsholder .outlinebtn {
    font-size: 14px;
  }
  .shiftdelete .shiftbtn,
  .shiftdelete .shiftdeletebtn {
    padding: 8px 15px;
  }
  .switchbuttonsgrid .buttonToggle {
    font-size: 0.8em;
    padding: 0.713em;
  }
  .swapreqest .buttonsholder button {
    min-height: 56px;
  }
}

@media only screen and (max-width: 400px) {
  .pagetitlebox {
    flex-direction: column;
    align-items: baseline;
  }
  .pagetitlebox h4 {
    margin-bottom: 10px;
  }
  .outlinebtn button,
  .fillbtn button {
    padding: 10px;
  }
}
